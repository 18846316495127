import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SessionService } from '@services/session.service';

export const mobileRedirectGuard: CanActivateFn = () => {
  const isMobile = inject(BreakpointObserver).isMatched([Breakpoints.Handset]);
  const session = inject(SessionService);
  const extras = session.thirdPartyToken
    ? { queryParams: { token: session.thirdPartyToken } }
    : undefined;
  return inject(Router).navigate([isMobile ? '/m' : '/d'], extras);
};
