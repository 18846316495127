import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { SessionService } from '@services/session.service';
import { GameService } from '@services/game.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { firstValueFrom } from 'rxjs';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';

export async function executeTokenLogin(
  sessionService: SessionService,
  gameService: GameService,
) {
  sessionService.user.set((window as WindowWithUser).user);
  const authenticated = !!sessionService.user();
  if (authenticated) {
    await firstValueFrom(gameService.fetchGame());
    await firstValueFrom(gameService.fetchTransactions(0));
  }
  return authenticated;
}

export const loggedInAuthGuard: CanActivateFn = async () => {
  const session = inject(SessionService);
  const gameService = inject(GameService);
  const router = inject(Router);
  const breakpointObserver = inject(BreakpointObserver);
  let authenticated = !!session.user();
  if (!authenticated) {
    if (session.thirdPartyToken) {
      authenticated = await executeTokenLogin(session, gameService);

      const isMobile = breakpointObserver.isMatched([Breakpoints.Handset]);
      if (authenticated && session.hasCurrentGameResults) {
        const extras = session.thirdPartyToken
          ? {
              queryParams: { token: session.thirdPartyToken },
            }
          : undefined;
        return router.navigate(
          isMobile ? ['/m/results'] : ['/d/results'],
          extras,
        );
      }
    }
  }
  return authenticated ? true : inject(Router).createUrlTree(['/error']);
};
