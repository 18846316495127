import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EnvironmentService } from '@app/services/environment.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData, DatePipe, CurrencyPipe } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeHr from '@angular/common/locales/hr';
import localeEn from '@angular/common/locales/en';
import localeSe from '@angular/common/locales/se';
import localePt from '@angular/common/locales/pt';
import localeFr from '@angular/common/locales/fr';
import localeSv from '@angular/common/locales/sv';
import localeEs from '@angular/common/locales/es';
import localeJa from '@angular/common/locales/ja';
import localePl from '@angular/common/locales/pl';

import { TranslationService } from '@app/services/translation.service';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeHr, 'hr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeSe, 'se');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localePl, 'pl');

export function startupServiceFactory(injector: Injector) {
  return () => {
    const envService = injector.get(EnvironmentService);
    envService.initializeSession();
  };
}

export function localeFactory() {
  const lang = (window as WindowWithUser).user.language || 'en';
  return TranslationService.supportedLanguages.includes(lang) ? lang : 'en';
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: localeFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe,
    CurrencyPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
