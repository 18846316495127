import { Component, effect, OnInit, Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { SessionService } from '@app/services/session.service';
import { Question } from '@shared/types/question';
import { UserActionService } from '@services/user-action.service';
import { UserAction } from '@longnecktech/splash-commons-fe';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isMobile$ = this.session.isMobile$;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly session: SessionService,
    private readonly userActionService: UserActionService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    effect(() => {
      if (!this.session.user()) {
        return;
      }
      console.log('user logged in', this.session.user()?.username);
    });

    /**
     * when we have actions for the quiz ready, we can use this to send the data to the backend
     */
    window.onbeforeunload = () => {
      if (!isEveryQuestionPicked(this.session.game()?.questions || [])) {
        const currentQuestion = this.session.currentQuestionId();
        const numberOfQuestions = this.session.game()?.questions.length || 0;
        this.userActionService
          .sendAction(UserAction.ABANDON_GAME, {
            progress: currentQuestion / numberOfQuestions,
          })
          .pipe(take(1))
          .subscribe();
      }
    };

    function isEveryQuestionPicked(questions: Question[]): boolean {
      return questions.every((question) =>
        question.options.some((option) => option.picked),
      );
    }
  }

  ngOnInit(): void {
    this.updateBodyClass();
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        const extras = this.session.thirdPartyToken
          ? { queryParams: { token: this.session.thirdPartyToken } }
          : undefined;

        if (result.matches && window.location.href.includes('/d')) {
          this.router.navigate(['/m'], extras);
          this.session.updateIsMobile(true);
        } else if (
          !result.matches &&
          (window.location.href.includes('/m') ||
            !window.location.href.includes('/d'))
        ) {
          this.router.navigate(['/d'], extras);
          this.session.updateIsMobile(false);
        } else if (!result.matches && window.location.href.includes('/d')) {
          this.session.updateIsMobile(false);
        }
      });
  }

  private updateBodyClass(): void {
    const bodyClass = 'mobile-version';
    this.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add(bodyClass);
      } else {
        this.document.body.classList.remove(bodyClass);
      }
    });
  }
}
